var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const ROOT_URL = 'https://storage.googleapis.com/liveresultater-public-files/';
const urlParams = new URLSearchParams(window.location.search);
const FORCED_INTERVAL_STR = urlParams.get('opdt');
let forcedInterval = Number.parseInt(FORCED_INTERVAL_STR);
if (!Number.isNaN(forcedInterval)) {
    // Ensure that we do not go below one second.
    forcedInterval = Math.max(1, forcedInterval);
}
export class NewWebsocket {
    Close() {
        this.killed = true;
    }
    constructor(collectionId, collectionsToTrigger) {
        this.lastPartHandled = new Map();
        this.killed = false;
        this.collectionId = collectionId;
        this.collectionsToTrigger = collectionsToTrigger;
        this.GetData();
    }
    ReadFullPart(collectionName, part, idFieldsFromManifest) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(ROOT_URL + this.collectionId + '/data/' + part.full);
            const json = yield response.json();
            const change = {
                upsert: json,
                delete: []
            };
            this.DataReceived(collectionName, change, true, idFieldsFromManifest);
        });
    }
    AnalyzePart(collectionName, part) {
        return __awaiter(this, void 0, void 0, function* () {
            if (part.data.length == 0) {
                return;
            }
            let lastPartHandled = null;
            if (this.lastPartHandled.has(collectionName)) {
                lastPartHandled = this.lastPartHandled.get(collectionName);
            }
            const lastPartFromBackend = part.data[part.data.length - 1];
            if (lastPartHandled == null || (lastPartFromBackend.delta == '' && lastPartFromBackend.full != lastPartHandled.full)) {
                // Read the full data
                yield this.ReadFullPart(collectionName, lastPartFromBackend, part.id);
            }
            else {
                // Find changes between last seen and the last from backend
                let needsFullReload = false;
                let add = false;
                let needToHandle = [];
                for (let i = 0; i < part.data.length; ++i) {
                    if (add) {
                        needToHandle.push(part.data[i]);
                        if (part.data[i].delta == '') {
                            needsFullReload = true;
                            break;
                        }
                    }
                    if (part.data[i].full == lastPartHandled.full) {
                        add = true;
                    }
                }
                if (!add) {
                    // We did not see our last handled file, so we need a full reload.
                    needsFullReload = true;
                }
                // Are any of these full reload?
                if (needsFullReload) {
                    yield this.ReadFullPart(collectionName, lastPartFromBackend, part.id);
                }
                else {
                    let promises = [];
                    for (let i = 0; i < needToHandle.length; ++i) {
                        promises.push({
                            index: i,
                            promise: fetch(ROOT_URL + this.collectionId + '/data/' + needToHandle[i].delta)
                        });
                    }
                    for (const row of promises) {
                        const response = yield row.promise;
                        const json = yield response.json();
                        this.DataReceived(collectionName, json, false, part.id);
                    }
                }
            }
            this.lastPartHandled.set(collectionName, lastPartFromBackend);
        });
    }
    GetData() {
        return __awaiter(this, void 0, void 0, function* () {
            let interval = 10000;
            try {
                const manifestFileName = Number.isNaN(forcedInterval) ? 'manifest' : 'manifest-nocache';
                const response = yield fetch(ROOT_URL + this.collectionId + '/' + manifestFileName);
                const data = yield response.json();
                let secondsSinceLastChange = Number.MAX_VALUE;
                let promises = [];
                for (const key in data) {
                    if (key == 'version') {
                        continue;
                    }
                    let found = false;
                    for (const val of this.collectionsToTrigger) {
                        if (val == key) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        continue;
                    }
                    const part = data[key];
                    const nowUnixSec = Date.now() / 1000;
                    secondsSinceLastChange = Math.min(secondsSinceLastChange, (nowUnixSec - part.last_modified));
                    promises.push(this.AnalyzePart(key, part));
                }
                yield Promise.all(promises);
                if (secondsSinceLastChange < 60) {
                    interval = 2000;
                }
                else if (secondsSinceLastChange < (10 * 60)) {
                    interval = 5000;
                }
            }
            catch (ex) {
                // Silently accept errors so that setTimeout still gets called below.
            }
            if (!Number.isNaN(forcedInterval)) {
                interval = forcedInterval * 1000;
            }
            if (!this.killed) {
                setTimeout(this.GetData.bind(this), interval);
            }
        });
    }
}
