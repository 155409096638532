export function GetClientId() {
    let clientId = localStorage.getItem('client_id');
    if (clientId === null) {
        clientId = RandomString(10);
        localStorage.setItem('client_id', clientId);
    }
    return clientId;
}
export function RandomString(length) {
    let result = '';
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
