export var RunnerStatus;
(function (RunnerStatus) {
    RunnerStatus[RunnerStatus["OK"] = 1] = "OK";
    RunnerStatus[RunnerStatus["REGISTERED"] = 2] = "REGISTERED";
    RunnerStatus[RunnerStatus["DSQ"] = 3] = "DSQ";
    RunnerStatus[RunnerStatus["DNF"] = 4] = "DNF";
    RunnerStatus[RunnerStatus["DNS"] = 5] = "DNS";
    RunnerStatus[RunnerStatus["NC"] = 6] = "NC";
})(RunnerStatus || (RunnerStatus = {}));
export const START_CODE = 'start';
export const FINISH_CODE = 'finish';
export const COMBINED_CODE = 'combined';
